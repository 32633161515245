<template lang="pug">
div
  div.event_list
    div.event(v-for="event in get_event_list(0)" v-bind:style="{ 'background-color': event.color }")
      div
        div.date {{ event.date }}
        div.message {{ event.message }}
</template>

<script>
import { mapGetters } from 'vuex'
import bus from '@/services/bus'

export default {
  name: 'Events',
  data () {
    return {
      selected_list: this.selected_list
    }
  },
  computed: {
    ...mapGetters({
      event_map: 'get_event_map'
    })
  },
  mounted () {
    this.selected_list = 'AL'
    bus.$on('event_filter', (filter) => { this.selected_list = filter })
  },
  beforeDestroy () {
    bus.$off('event_filter')
  },
  methods: {
    get_event_list: function (unused_index) {
      for (let eventlist of this.event_map) {
        if (eventlist.event_type !== this.selected_list) continue
        return JSON.parse(eventlist['content'])
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/colors';
@import '../assets/constants';
@import '../assets/mixins';

.navbar {
  margin-bottom: 5px;
}

.event_list {
  font-size: 110%;
  padding-top: .3em;
}

.event {
  border-radius: 3px;
  box-shadow: 0 0 .25rem $black_shadow_light;
  color: $white;
  display: table;
  line-height: 1;
  margin: 0;
  margin-bottom: .1rem;
  padding: .3rem;
  width: 100%;
}

.date {
  display: table-cell;
  float: left;
  margin: 0;
  overflow: hidden;
  padding: 0;
  padding-bottom: 3px;
  padding-left: 3px;
  padding-top: 3px;
  vertical-align: middle;
  width: 25%;
}

.message {
  display: table-cell;
  float: right;
  margin: 0;
  overflow: hidden;
  padding: 4px;
  text-align: right;
  vertical-align: middle;
  width: 75%;
}
</style>
