<template lang="pug">
div
  data-list-modal(ref="data_list_modal")
  user-confirm-modal(ref="user_confirm_modal")
  b-modal(ref="control_modal_ref" modal-class="own_modal control_modal" :no-auto-focus="true" 
          :no-enforce-focus="true")
    //- HEADER
    template(slot="modal-header")
      div.content(v-bind:style="{'background-color': data_color(main_data)}")
        div.inner.pb-3
          div.title Control Settings
        div.spinner
          clip-loader(:loading="loading" size="40px")

    //- CONTENT
    b-button-group.data.main(v-bind:style="{'background-color': data_color(main_data)}")
      b-button.data.main.name(@click="show_data_list_modal('main_data')") {{ main_data.name }}
        icon.form-select(name="sort-down")
      //- b-button.data_edit
    template(v-for="child in child_map")
      b-button-group.data.child(v-bind:style="{'background-color': data_color(child)}")
        b-button.name(@click="show_data_list_modal(identifier(child))") {{ child.name }}
          icon.form-select(name="sort-down" @click="show_data_list_modal(identifier(child))")
        b-button.remove(variant="danger" @click="delete_child(child)")
          icon(name="trash")
    b-button-group
      b-button.add(v-bind:class="on_child_limit ? 'disabled' : ''" variant="primary" size="lg"
                   @click="new_child_data()")
        icon(name="plus")
        span.pl-3 Add data
      b-button.delete(v-if="!new_control" @click="delete_control()" variant="danger" size="lg")
        icon(name="trash")
        span.pl-3 Delete control
    //- FOOTER
    template(slot="modal-footer")
      b-button-group
        b-button.left_radius(@click="hide()" :variant="!no_submit ? 'danger' : 'success'"
                             size="lg") {{ no_submit ? 'Close' : 'Cancel' }}
        b-button.submit.right_radius(@click="submit" size="lg"
                        :variant="no_submit ? 'warning' : 'success' "
                        v-bind:class="no_submit ? 'disabled' : '' ") Apply
</template>

<script>
import Icon from 'vue-awesome/components/Icon'
import 'vue-awesome/icons/pencil-alt'
import 'vue-awesome/icons/trash'
import 'vue-awesome/icons/plus'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import { mapGetters } from 'vuex'
import bus from '@/services/bus'
import Data_list_modal from '@/components/Data_list_modal.vue'
import User_confirm_modal from '@/components/User_confirm_modal.vue'
import { get_element_by_id, get_index_by_id, get_host_color } from '@/utils'
import CONF from '@/conf'
var isEqual = require('lodash/isEqual')

const EMPTY_DATA = {id: null, name: null, shortname: null, host: null}

export default {
  name: 'Control_edit_modal',
  components: {
    Icon,
    'clip-loader': ClipLoader,
    'data-list-modal': Data_list_modal,
    'user-confirm-modal': User_confirm_modal
  },

  data () {
    return {
      child_map: [],
      controlchild_map: [],
      control: null,
      data_list_modal: null,
      error: false,
      loading: false,
      main_data: null,
      modal: null,
      new_control: false,
      no_submit: true
    }
  },

  computed: {
    ...mapGetters({
      control_map: 'get_control_map',
      data_map: 'get_data_map',
      host_map: 'get_host_map'
    }),
    on_child_limit: function () {
      if (this.child_map.length >= 4) return true
      return false
    }
  },

  watch: {
    main_data: function (newvalue, oldvalue) {
      if (!oldvalue || !newvalue) return
      if (oldvalue) { if (!oldvalue.id) return }
      if (!newvalue.id) return
      this.update_submit_state()
    },

    child_map: function (newvalue, oldvalue) {
      if (!oldvalue || !newvalue || this.disabled) return
      this.update_submit_state()
    }
  },

  created () {
    this.main_data = EMPTY_DATA
  },

  mounted () {
    this.modal = this.$refs['control_modal_ref']
    this.data_list_modal = this.$refs['data_list_modal']
  },

  beforeDestroy: function () {
    bus.$off('data_selection/main_data')
    for (let child_data of this.controlchild_map) {
      bus.$off(`data_selection/${this.identifier(child_data)}`)
    }
  },

  methods: {
    show: function (control_pk) {
      this.disabled = false
      bus.$on('data_selection/main_data', (data_pk) => {
        if (data_pk == null) return
        if (this.new_control) this.modal.show()
        this.main_data = get_element_by_id(this.data_map, data_pk)
      })
      if (control_pk) {
        this.control = get_element_by_id(this.control_map, control_pk)
        this.main_data = get_element_by_id(this.data_map, this.control.data)
        let index = 0
        for (let child_data_pk of this.control.childdata_map) {
          let child_data = get_element_by_id(this.data_map, child_data_pk)
          this.controlchild_map.push(child_data)
          const child_index = index
          this.new_child_data_callback(child_index)
          index += 1
        }
        this.update_child_map()
        this.modal.show()
      } else {
        this.new_control = true
        this.no_submit = false
        this.show_data_list_modal('main_data')
      }
    },

    hide: function () {
      bus.$off('data_selection/main_data')
      for (let child_data of this.controlchild_map) {
        bus.$off(`data_selection/${child_data.identifier}`)
      }
      this.modal.hide()
      this.disabled = true
      this.no_submit = true
      this.loading = false
      this.main_data = EMPTY_DATA
      this.controlchild_map = []
      this.child_map = []
      this.new_control = false
    },

    update_submit_state: function () {
      if (this.new_control) return
      let maindata_is_new = (this.main_data.id !== this.control.data)
      let new_childdata_map = []
      for (let child of this.child_map) { new_childdata_map.push(child.id) }
      new_childdata_map = new_childdata_map.sort((a, b) => a - b)
      let old_childdata_map = this.control.childdata_map.sort((a, b) => a - b)
      let childmap_is_new = (!isEqual(new_childdata_map, old_childdata_map))
      this.no_submit = !(maindata_is_new || childmap_is_new)
    },

    update_child_map: function () {
      this.child_map = []
      for (let child of this.controlchild_map) this.child_map.push(child)
    },

    post_request: function (url, content) {
      let request = this.$http.post(
        url, content, CONF.REQUEST_OPTIONS)
      request.then(
        unused_response => { this.hide() },
        response => {
          console.log(`Error POST request: ${response.status} ${response.body.text}`)
          this.$toaster['error'](response.body.text)
          this.loading = false
        }
      )
    },

    delete_control: function () {
      const identifier = `control_${this.control.id}`
      this.$refs['user_confirm_modal'].show(identifier, {
        confirm_message: `Remove control for ${this.main_data.name} ?`
      })
      bus.$on(`user_confirm/${identifier}`, (confirm) => {
        console.log(`Got user confirm ${identifier}`)
        bus.$off(`user_confirm/${identifier}`)
        if (confirm !== true) return
        this.post_request(CONF.CONTROL_DELETE_URL, {id: this.control.id})
      })
    },

    delete_child: function (data) {
      let index = get_index_by_id(this.controlchild_map, data.id)
      this.controlchild_map.splice(index, 1)
      this.update_child_map()
    },

    data_color: function (data) {
      if (!data) return
      return get_host_color(this.host_map, data.host)
    },

    identifier: function (data) {
      if (!data) return
      return `child_data_${get_index_by_id(this.controlchild_map, data.id)}`
    },

    new_child_data_callback: function (index) {
      bus.$on(`data_selection/child_data_${index}`, (data_pk) => {
        this.modal.show()
        if (data_pk == null) return
        this.controlchild_map[index] = get_element_by_id(this.data_map, data_pk)
        this.update_child_map()
      })
    },

    new_child_data: function () {
      if (this.on_child_limit) return
      const index = this.controlchild_map.length
      this.new_child_data_callback(index)
      this.data_list_modal.show(`child_data_${index}`, true)
    },

    show_data_list_modal: function (identifier) {
      let exclude_map = [ this.main_data.id ]
      for (let child of this.child_map) exclude_map.push(child.id)
      let title_part = identifier.includes('child') ? '' : 'main'
      this.data_list_modal.show(identifier, {
        title: `Select ${title_part} control data`, exclude_map: exclude_map})
    },

    submit: function () {
      this.error = ''
      this.loading = true
      let child_pk_map = []
      for (let data of this.controlchild_map) child_pk_map.push(data.id)
      let post_content = {
        id: this.new_control ? 'new' : this.control.id, data: this.main_data.id,
        datachild_map: JSON.stringify(child_pk_map) }
      this.post_request(CONF.CONTROL_SAVE_URL, post_content)
    }
  },
}
</script>

<style lang="scss">
@import '../assets/colors';
@import '../assets/constants';
@import '../assets/mixins';

.control_modal {
  .modal-header {
    .content {
      min-height: 50px;
      .inner {
        .title {
          color: $white !important;
        }
      }

      .spinner {
        background-color: transparent !important;
        position: absolute;
        right: 0;
        top: 0;
        .v-clip {
          border-color: lighten($white, 20) lighten($white, 30)  transparent !important;
        }
      }
    }
  }

  .modal-body {
    button {
      border: 0;
      border-bottom: 1px solid $white_shadow_light;
      border-collapse: collapse;
      display: block;
      &.name {
        background-color: transparent;
        text-align: left;
        &:hover {
          background-color: $black_shadow_light !important;
        }
      }
      &.main {
        font-size: 1.7rem;
        width: 100% !important;
        .fa-icon.form-select {
          color: $white;
          height: 16px;
          top: .8rem;
        }
      }
      .fa-icon.form-select {
        color: $white;
        height: 16px !important;
        top: .8rem;
      }
    }
    .btn-group.child {
      button {
        &.name {
          font-size: 1.2rem;
          padding-left: 2rem;
        }
        &.remove {
          max-width: 40px;
          .fa-icon {
            height: 16px;
          }
        }
      }
    }
  }
  // .modal-footer {
  // }
}
</style>
