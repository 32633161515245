<template lang="pug">
div
  vue-headful(title="Ownmesh controls")
  set-order-modal(ref="control_order_modal" main_prop="control")
  control-edit-modal(ref="control_edit_modal")
  data-modal(ref="data_modal")

  div.root_container
    div.control_container(ref="controls_container")
      div.control_instance(v-for="control_item in ordered_control_map"
                           v-bind:style="{'width': control_width_str}")
        control-instance(v-if="data_modal" :control_pk="control_item.id" :data_modal="data_modal"
                         :width="control_width")
    div.event_container
      template(v-if="show_events")
        events
</template>

<script>
import { mapGetters } from 'vuex'
import bus from '@/services/bus'
import debounce from 'debounce'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
var orderBy = require('lodash/orderBy')

import CONF from '@/conf'
import Control_instance from '@/components/Control_instance.vue'
import Set_order_modal from '@/components/Set_order_modal.vue'
import Data_modal from '@/components/Data_modal.vue'
import Control_edit_modal from '@/components/Control_edit_modal.vue'
import Events from '@/components/Events.vue'

export default {
  name: 'Control_landing',
  components: {
    'control-instance': Control_instance,
    'set-order-modal': Set_order_modal,
    'events': Events,
    'data-modal': Data_modal,
    'control-edit-modal': Control_edit_modal,
    ClipLoader
  },

  data () {
    return {
      container_width: null,
      control_width: CONF.MIN_CONTROL_WIDTH,
      control_height: CONF.MIN_CONTROL_WIDTH / 1.2,
      data_modal: null,
      show_events: true,
      event_width: '50px',
      new_control_url: `${CONF.ROOT_URL}admin/controls/control/add/`
    }
  },

  computed: {
    ...mapGetters({
      socket_state: 'get_socket_state',
      control_map: 'get_control_map',
      host_map: 'get_host_map',
      data_map: 'get_data_map',
      control_landing_state: 'get_control_landing_state',
      data_landing_state: 'get_data_landing_state',
      host_landing_state: 'get_host_landing_state'
    }),
    ordered_control_map () {
      return orderBy(this.control_map, 'position')
    },
    control_width_str () {
      return `${this.control_width}px`
    }
  },

  mounted () {
    this.data_modal = this.$refs['data_modal']
    this.control_edit_modal = this.$refs['control_edit_modal']
    if (this.control_landing_state) {
      bus.$emit('route/ready', null)
    }
    bus.$on('socket/state', (response) => {
      if (!response || this.control_landing_state) return
      let already_fetched = ''
      if (this.host_landing_state) { already_fetched += 'host,' }
      if (this.data_landing_state) { already_fetched += 'data,' }
      if (this.event_landing_state) { already_fetched += 'event' }
      this.$socket.send('control_landing', JSON.stringify({already_fetched: already_fetched}))
    })
    bus.$on('state/control_landing', (state) => {
      if (!state) return
      // this.$toaster['info']('Ready !')
      bus.$emit('route/ready', null)
    })
    bus.$on('control/edit', (control_pk) => {
      this.control_edit_modal.show(control_pk)
    })
    bus.$on('show_control_add_modal', () => {
      this.control_edit_modal.show()
    })
    this.$socket.connect()
    this.get_element_sizes = () => {
      this.container_width = this.$refs.controls_container.clientWidth
      let columns = Math.floor(this.container_width / CONF.MIN_CONTROL_WIDTH)
      this.control_width = Math.floor(this.container_width / columns) - 10
    }
    this.get_element_sizes()
    this.get_element_sizes = debounce(this.get_element_sizes, 100)
    window.addEventListener('resize', this.get_element_sizes)
  },

  created () {
  },

  beforeDestroy () {
    bus.$off('control/edit')
    bus.$off('socket/state')
    bus.$off('state/control_landing')
    bus.$off('update/control_map')
    window.removeEventListener('resize', this.get_element_sizes)
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/colors';
@import '../assets/constants';
@import '../assets/mixins';

@media only screen and (min-width: 0) {
  .control_container {
    width: 100%;
  }
  .event_container {
    display: none;
    width: 0%;
  }
}

@media only screen and (min-width: 576px) {
  .control_container {
    width: 70%;
  }
  .event_container {
    display: inline-block;
    width: 30%;
  }
}

@media only screen and (min-width: 768px) {
  .control_container {
    width: 75%;
  }
  .event_container {
    width: 25%;
  }
}

.control_container {
  display: inline-block;
  .navbar {
    margin-left: 4px;
    width: 98%;
  }

  .control_instance {
    @include ease-size-transition(.3s);
    color: $white;
    display: inline-grid;
    height: 200px;
    margin: .25em;
    position: relative;
  }
}

.event_container {
  border-right: 4px solid transparent;
  float: right;
}
</style>
