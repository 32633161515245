<template lang="pug">
div.control_item(v-if="control_pk" v-bind:style="{'background-color': host_color }")
  div.row.main
    div.col.main
      div.value(@click="show_data_modal(main_data)"
                :class="main_value_class")
        data-widget(:data="main_data" :value="main_value" size="big")

    div.col.status_icon
      div(v-bind:class="host_netstatus")
  div.row.main_name(@click="show_control_edit_modal(control.id)" :class="main_value_class")
    |{{main_data_name}}
    //-| {{ main_data.name }}
  div.childs
    template(v-for="k in [0,1]")
      div.row
        div.col(v-for="i in [k*2, (k*2)+1]")
          div.value(v-if="is_child(i)" @click="show_data_modal(child_map[i])"
                    :class="get_child_value_class(i)")
            data-widget(:data="child_map[i]" :value="get_child_value(i)" size="small")

      div.row.name
        div.col.child(v-for="i in [k*2, (k*2)+1]")
          span(v-if="is_child(i)" @click="show_data_modal(child_map[i])"
               :class="get_child_value_class(i)") {{ child_map[i].shortname }}

  div.footer
    div.host_name {{ host_name }}
</template>

<script>
import { mapGetters } from 'vuex'
import { get_element_by_id, get_index_by_id } from '@/utils'
import { update_or_create } from '@/services/store'
import bus from '@/services/bus'
import Data_widget from '@/components/Data_widget'

export default {
  name: 'Control_instance',
  components: {
    'data-widget': Data_widget
  },
  props: {
    'control_pk': {type: Number, required: true},
    'data_modal': {type: Object, required: true},
    'width': {type: Number, required: true},
  },
  data () {
    return {
      control: null,
      main_data: null,
      main_lastentry: null,
      main_value_class: 'zoom_out',
      main_pending_class: 'zoom_out',
      main_value: null,
      main_pending: null,
      main_unit: null,
      host: null,
      host_lastentry: null,
      host_name: null,
      host_netstatus: null,
      child_map: [],
      child_lastentry_map: [],
      child_value_0: null, child_value_1: null, child_value_2: null, child_value_3: null,
      child_value_0_class: 'zoom_out', child_value_1_class: 'zoom_out',
      child_value_2_class: 'zoom_out', child_value_3_class: 'zoom_out',
      watcher_map: []
    }
  },

  computed: {
    ...mapGetters({
      control_map: 'get_control_map',
      host_map: 'get_host_map',
      data_map: 'get_data_map',
      lastentry_map: 'get_lastentry_map'
    }),
    host_color () {
      if (!this.host) return '#777'
      return this.host.color
    },
    main_data_name () {
      if (this.width < 210) return this.main_data.shortname
      return this.main_data.name
    }
  },

  watch: {
    control_pk: function (_, old_value) {
      if (old_value) this.reset()
      setTimeout(this.update, 20)
    }
  },

  created () {
    this.update()
    // this.control = this.value
  },

  beforeDestroy: function () {
    this.reset()
  },

  methods: {
    show_data_modal: function (data) {
      this.data_modal.show(data.id)
    },
    show_control_edit_modal: function (unused_data) {
      bus.$emit('control/edit', this.control.id)
    },
    is_child: function (index) {
      return this.get_child(index)
    },
    get_child_value: function (index) {
      let data = this.get_child(index)
      if (!data) return null
      let lastentry = get_element_by_id(this.child_lastentry_map, data.id)
      if (!lastentry) return null
      return lastentry.value
    },
    get_child_value_class: function (index) {
      return this.$data[`child_value_${index}_class`]
    },
    update: function () {
      this.control = get_element_by_id(this.control_map, this.control_pk)
      this.main_data = get_element_by_id(this.data_map, this.control.data)
      // if (!this.main_data) return
      this.main_lastentry = get_element_by_id(this.lastentry_map, this.main_data.id)
      this.main_value = this.main_lastentry ? this.main_lastentry.value : null
      this.main_pending = this.main_data.pending
      this.main_unit = this.main_data ? this.main_data.unit : null

      this.host = get_element_by_id(this.host_map, this.main_data.host)
      this.host_lastentry = null
      this.host_name = null
      this.host_netstatus = 'offline'

      // Get child data
      this.get_child = (index) => {
        if (this.child_map.length < index + 1) return null
        return this.child_map[index]
      }

      // Load alarm state from store if data found on it
      this.set_alarm_state = (class_name, state) => {
        let d = this.$data
        if (state && (d[class_name].search('value_alarm') === -1)) {
          d[class_name] += ' value_alarm'
        }
        if (!state && (d[class_name].search('value_alarm') !== -1)) {
          d[class_name] -= 'value_alarm'
        }
      }

      // Animate data value on change
      this.add_watcher = (data_name) => {
        return this.$watch(data_name, function (newValue, oldValue) {
          if (oldValue == null) return
          let d = this.$data
          // let data_class = d[`${data_name}_class`]
          d[`${data_name}_class`] = 'zoom_in'
          setTimeout(function () { d[`${data_name}_class`] = 'zoom_out' }, 200)
        })
      }
      this.watcher_map.push(this.add_watcher('main_value'))
      this.watcher_map.push(this.add_watcher('main_pending'))

      this.populate_host = () => {
        this.host_name = this.host.name
        if (this.host.type === 'local' || this.host.power_mode === 'DS') {
          this.host_netstatus = 'online'
          return
        }
        this.host_lastentry = get_element_by_id(this.lastentry_map, this.host.netstatus_data)
        if (this.host_lastentry) {
          this.host_netstatus = this.host_lastentry.value ? 'online' : 'offline'
        }
        bus.$on(`update/lastentry/${this.host.netstatus_data}`, (data) => {
          this.host_netstatus = data.value ? 'online' : 'offline'
        })
      }
      if (this.host) { this.populate_host() }

      this.populate_childs = () => {
        this.child_map = []
        this.child_lastentry_map = []
        // Update control state : important !
        this.control = get_element_by_id(this.control_map, this.control.id)
        for (let child_id of this.control.childdata_map) {
          let child = get_element_by_id(this.data_map, child_id)
          if (!child) continue
          let index = this.child_map.push(child) - 1
          this.set_alarm_state(`child_value_${index}_class`, child.on_alarm)
          // Value change animation
          this.watcher_map.push(this.add_watcher(`child_value_${index}`))
          bus.$on(`update/data/${child_id}`, (data) => {
            update_or_create(this.child_map, data)
            let index = get_index_by_id(this.child_map, data.id)
            if (index < 0) return
            this.set_alarm_state(`child_value_${index}_class`, data.on_alarm)
          })
          bus.$on(`update/lastentry/${child_id}`, (data) => {
            update_or_create(this.child_lastentry_map, data)
            let index = get_index_by_id(this.child_map, data.id)
            this.$data[`child_value_${index}`] = data.value
          })
          let child_lastentry = get_element_by_id(this.lastentry_map, child_id)
          if (!child_lastentry) continue
          this.child_lastentry_map.push(child_lastentry)
        }
      }
      this.populate_childs()

      this.set_alarm_state('main_value_class', this.main_data.on_alarm)

      let index = 0
      for (let child_data of this.child_map) {
        this.set_alarm_state(`child_value_${index}_class`, child_data.on_alarm)
        index += 1
      }

      // Signals from store callbacks
      bus.$on(`update/lastentry/${this.main_data.id}`, (data) => {
        this.main_lastentry = data
        this.main_value = data.value
      })
      bus.$on(`update/data/${this.main_data.id}`, (data) => {
        this.main_data = data
        this.main_pending = data.pending
      })
      bus.$on(`update/control/${this.control.id}`, (data) => {
        this.control = data
        this.populate_childs()
      })
      bus.$on(`update/host/${this.main_data.host}`, (data) => {
        this.host = data
        this.populate_host()
      })
    },

    reset: function () {
      for (let unwatch of this.watcher_map) unwatch()
      this.watcher_map = []
      bus.$off(`update/lastentry/${this.main_data.id}`)
      bus.$off(`update/data/${this.main_data.id}`)
      bus.$off(`update/lastentry/${this.main_data.alarm_data.id}`)
      bus.$off(`update/control/${this.control.id}`)
      bus.$off(`update/host/${this.main_data.host}`)
      bus.$off(`update/lastentry/${this.host.netstatus_data}`)
      for (let child_id of this.control.childdata_map) {
        bus.$off(`update/data/${child_id}`)
        bus.$off(`update/lastentry/${child_id}`)
        let child_data = get_element_by_id(this.data_map, child_id)
        if (!child_data) { continue }
        bus.$off(`update/lastentry/${child_data.alarm_data.id}`)
      }
    }
  },
}
</script>

<style lang="scss">
@import '../assets/colors';
@import '../assets/mixins';

.zoom_in {
  @include zoomin-transition;
  color: $red;
}

.zoom_out {
  @include zoomout-transition;
  color: inherit;
}

// .value_alarm {
//   @include infinite-zoomin;
//   text-shadow: -1px 0 $light_red, 0 1px $light_red, 1px 0 $light_red, 0 -1px $light_red;
// }

@media only screen and (min-width: 0) {
  .control_item {
    font-size: 11px !important;
  }
}

@media only screen and (min-width: 576px) {
  .control_item {
    font-size: 10px !important;
  }
}


.control_item {
  border: 1px solid $white_shadow;
  border-radius: 3px;
  box-shadow: 0 0 .25rem $black_shadow_light;
  padding: 4px 6px;

  .row {
    margin: 0;

    .main {
      font-size: 3em;
      height: 30px;
    }
  }

  .col {
    margin-top: -.4rem;
    padding: 0;
    .value {
      display: table;
      position: relative;
      span {
        cursor: pointer;

        &.fake {
          color: $tranparent;
        }
      }
    }
  }

  .status_icon {
    height: 32px;
    margin-top: -2px;
    width: 32px;

    div {
      height: inherit;
      position: absolute;
      right: 0;
      width: inherit;
    }

    .offline {
      background-image: url('../assets/offline.svg');
    }
  }

  .main {
    .vue-switcher {
      left: 0;
      position: absolute;
      top: 14px;
    }
    .pending_icon {
      right: -8px;
      top: -18px;
    }
    .alarm_icon {
      left: -5px;
      top: -15px;
    }
  }

  .main_name {
    cursor: pointer;
    font-size: 1.4em;
    min-height: 35px;
    // margin-top: -8px;
  }

  .childs {
    bottom: 28px;
    left: 4px;
    position: absolute;
    right: 4px;
    text-align: center;
    top: 70px;

    .fake {
      display: none;
    }

    .row {
      height: 24px;
      margin-top: 5px;
    }

    .row.name {
      cursor: pointer;
      margin-top: 0;
      padding-bottom: 10px;
      padding-top: 1px;
    }

    .value {
      font-size: 1.8em;
      margin: 0 auto;
      width: 50%;
    }

    .vue-switcher {
      padding-top: 4px;
    }

    .pending_icon {
      right: -8px;
      top: -8px;
    }

    .alarm_icon {
      left: -5px;
      top: -5px;
    }
  }

  .footer {
    background-color: $black;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    bottom: 1px;
    left: 1px;
    line-height: 20px;
    opacity: .4;
    padding: 0;
    position: absolute;
    right: 1px;

    .host_name {
      font-size: 110%;
      padding-right: 4px;
      text-align: right;
      vertical-align: middle;
    }
  }
}
</style>
